<script setup>
import { ref } from 'vue'
import HomeBackgroundPictureXL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-2048x1365.jpg'
import HomeBackgroundPictureL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1780×1187.jpg'
import HomeBackgroundPictureM from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1460×973.jpg'
import HomeBackgroundPictureS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1060×707.jpg'
import HomeBackgroundPictureXS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-300x200.jpg'

import BrahimDiouane from '../../assets/images/brahim-diouane.jpg'
import ToufikeLebouazda from '../../assets/images/toufike-lebouazda.jpg'

import MainButtonComponent from '../MainButtonComponent.vue'

import linkedinLogo from '../../assets/logos_pictos/linkedin-logo-dark.svg'

const HomeBackgroundPictureSrcset = `${HomeBackgroundPictureXS} 300w, ${HomeBackgroundPictureS} 1060w, ${HomeBackgroundPictureM} 1460w, ${HomeBackgroundPictureL} 1780w, ${HomeBackgroundPictureXL} 2048w`
const HomeBackgroundPictureSizes = '100vw'
</script>

<template>
  <div class="contact__email__cover">
    <h1 class="contact__email__cover__title">Nous contacter</h1>
    <img class="contact__email__cover__image" :srcset="HomeBackgroundPictureSrcset" :sizes="HomeBackgroundPictureSizes"
      alt="escalier en béton avec un arbre au centre" :src="HomeBackgroundPictureXL" loading="lazy" />
  </div>
  <div class="contact__email__content">
    <div class="contact__email__content__collaborator">
      <div class="contact__email__content__collaborator__card">
        <img class="contact__email__content__collaborator__card__picture" :src="BrahimDiouane" />
        <h2>Brahim Diouane</h2>
        <p>CEO</p>
      </div>
      <div class="contact__email__content__collaborator__card">
        <img class="contact__email__content__collaborator__card__picture" :src="ToufikeLebouazda" />
        <h2>Toufike Lebouazda</h2>
        <p>CTO</p>
      </div>
    </div>
    <p class="contact__email__content__paragraph">Howeco est en cours de développement. Nous serions ravis d’échanger avec
      vous. Si d’aventure vous avez des questions ou si vous souhaitez tester nos solutions en avant première.</p>
    <div class="contact__email__content__link">
      <a href="https://www.linkedin.com/in/brahim-diouane/" class="contact__email__content__link__linkedin">
        <p class="contact__email__content__link__linkedin__text">Retrouvez Brahim sur<img :src="linkedinLogo"
            alt="logo linkedin" width="30" height="30" /></p>
      </a>
      <a href="https://www.linkedin.com/company/howeco-company/" class="contact__email__content__link__linkedin">
        <p class="contact__email__content__link__linkedin__text">Retrouvez-nous sur<img :src="linkedinLogo"
            alt="logo linkedin" width="30" height="30" /></p>
      </a>
      <a href="mailto:diouanebrahim@gmail.com">
        <MainButtonComponent buttonContent="Contact par mail" />
      </a>
    </div>
  </div>
</template>

<style scope lang="scss">
.contact__email__cover {
  position: relative;
  display: flex;
  align-items: center;
  margin: 9.375rem auto 4.688rem auto;
  width: calc(100% - 18.75rem);
  max-width: calc(120rem - 55.625rem);

  &__title {
    text-align: left;
    font-family: DM sans, sans-serif;
    font-weight: bold;
    font-size: 4rem;
    position: absolute;
    margin: 0;
    left: -4.688rem;
    z-index: 1;
  }

  &__image {
    width: 100%;
    max-width: 73.125rem;
    height: 15.938rem;
    object-fit: cover;
    opacity: 0.2;
    position: relative;
    left: 4.688rem;
    border-radius: 0.375rem;
  }
}

.contact__email__content {
  &__collaborator {
    display: flex;
    justify-content: center;
    gap: 3.125rem;

    &__card {
      margin-bottom: 2.031rem;
    }

    &__card p {
      font-size: 1.375rem;
    }

    &__card__picture {
      width: 9.375rem;
    }
  }

  &__paragraph {
    font-family: roboto, sans-serif;
    font-size: 1.25rem;
    margin: auto;
    text-align: left;
    width: calc(100% - 9.375rem);
    max-width: calc(120rem - 65rem);
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    margin-top: 3.75rem;
    margin-bottom: 7.5rem;

    &__linkedin {
      color: var(--primary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &__text {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
}

@media screen and (max-width: 48rem) {
  .contact__email__cover {
    width: calc(100% - 6.563rem);
    margin: 4.688rem auto 2.813rem auto;

    &__title {
      font-size: 2.25rem;
      top: 1.25rem;
      left: -1.406rem;
    }

    &__image {
      height: 14.688rem;
      left: 1.406rem;
    }
  }

  .contact__email__content {
    &__collaborator {
      gap: 1.25rem;
      margin: 7.5rem 1.875rem 3.75rem 1.875rem;
    }

    &__paragraph {
      font-size: 1rem;
      width: calc(100% - 3.75rem);
    }

    &__link {
      flex-direction: column;
      margin-left: 1.875rem;
      margin-right: 1.875rem;
      margin-top: 3.75rem;

      &__linkedin {
        align-self: flex-start;
      }
    }
  }
}
</style>
