<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  buttonContent: String
})
</script>

<template>
  <button class="main__button">{{ props.buttonContent }}</button>
</template>

<style scope lang="scss">
.main__button {
  cursor: pointer;
  font-family: DM sans, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.625rem 2.188rem;
  border-radius: 0.188rem;
  border-style: none;
  background: linear-gradient(to right, var(--main-gardient-first-color), var(--gradient-transparency));
  ;
  background-color: var(--main-gradient-second-color-before);
  color: var(--primary-color-dark-bg);
  z-index: 1;
  transition: background-color 700ms;

  &:hover {
    background-color: var(--main-gradient-second-color-after);
  }
}

@media screen and (max-width: 96rem) {
  .main__button {
    font-size: 1.25rem;
    padding: 0.5rem 1.875rem;
    border-radius: 0.188rem;
    border-style: none;
  }
}
</style>
