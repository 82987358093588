import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ImplicationsView from '../views/ImplicationsView.vue'
import ServiceView from '../views/ServiceView.vue'
import ContactView from '../views/ContactEmailToView.vue'
import Error404View from '../views/Error404View.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/enjeux',
    name: 'implications',
    component: ImplicationsView
  },
  {
    path: '/service',
    name: 'service',
    component: ServiceView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/:notFound',
    name: '404',
    component: Error404View
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
