<script setup defer>
import HomeBackgroundPictureXL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-2048x1365.jpg'
import HomeBackgroundPictureL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1780×1187.jpg'
import HomeBackgroundPictureM from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1460×973.jpg'
import HomeBackgroundPictureS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1060×707.jpg'
import HomeBackgroundPictureXS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-300x200.jpg'

import MainButtonComponent from '../MainButtonComponent.vue'

const HomeBackgroundPictureSrcset = `${HomeBackgroundPictureXS} 300w, ${HomeBackgroundPictureS} 1060w, ${HomeBackgroundPictureM} 1460w, ${HomeBackgroundPictureL} 1780w, ${HomeBackgroundPictureXL} 2048w`
const HomeBackgroundPictureSizes = '100vw'
</script>

<template>
    <div class="home__landing">
        <img :srcset="HomeBackgroundPictureSrcset" :sizes="HomeBackgroundPictureSizes" class="home__landing__picture"
            alt="escalier en béton avec un arbre au center" :src="HomeBackgroundPictureXL" loading="lazy" />
        <div class="home__landing__central">
            <h1 class="home__landing__central__title">L’outil intelligent d’aide à l’éco-construction</h1>
            <p class="home__landing__central__sub__title">Et si vous utilisiez les outils disponibles les plus puissants
                pour concevoir les constructions de demain ?</p>
            <router-link to="/contact">
                <MainButtonComponent buttonContent="Nous contacter" />
            </router-link>
        </div>
        <div class="home__landing__aside">
            <p class="home__landing__aside__text">Vous vous interrogez sur les pratiques écoresponsables dans les projets
                d'infrastructure ? Faites défiler pour en apprendre davantage.</p>
        </div>
    </div>
</template>

<style scope lang="scss" onload="this.media='all'">
.home__landing {
    font-family: DM sans, sans-serif;
    font-weight: bold;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-background-color);

    &__picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(50%);
        position: absolute;
        top: 0;
        left: 0;
    }

    &__central {
        position: relative;
        z-index: 1;
        width: calc(100% - 9.375rem);
        max-width: calc(120rem - 46.875rem);
        margin: auto;
        text-align: left;
        color: var(--primary-color-dark-bg);

        &__title {
            font-size: 4rem;
            margin-bottom: 0.938rem;
            max-width: 43.75rem;
        }

        &__sub__title {
            margin-top: 0;
            font-size: 2rem;
            max-width: 56.25rem;
        }
    }

    &__aside {
        width: calc(100% - 9.375rem);
        max-width: calc(120rem - 9.375rem);
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        &__text {
            font-size: 1.5rem;
            text-align: left;
            background-color: var(--primary-color-dark-bg);
            color: var(--primary-color);
            max-width: 40.625rem;
            border-radius: 0.375rem 0.375rem 0 0;
            padding: 4.063rem 9.375rem;
            margin: 0;
        }
    }
}

@media screen and (max-width: 96rem) {
    .home__landing {
        &__central {
            width: calc(100% - 3.75rem);

            &__title {
                font-size: 2.25rem;
                max-width: 28.125rem;
            }

            &__sub__title {
                font-size: 1rem;
            }
        }

        &__aside {
            display: none;
        }
    }
}
</style>
