<script setup>
import { ref, watch, reactive } from 'vue'
import { useRoute } from 'vue-router'
import blackLogo from '../assets/logos_pictos/logo-howeco-black.svg'
import whiteLogo from '../assets/logos_pictos/logo-howeco-white.svg'

const headerLogo = ref(whiteLogo)
const headerLink = reactive({})
const headerBar = reactive({})
const headerMenu = reactive({})

const currentRoute = useRoute()
const currentRoutePath = ref(currentRoute.path)

const primaryColor = getComputedStyle(
  document.documentElement
).getPropertyValue('--primary-color')

const primaryColorDarkBG = getComputedStyle(
  document.documentElement
).getPropertyValue('--primary-color-dark-bg')

// Allow keeping the desired style when refreshing the page
const UpdateHeaderStyle = (path) => {
  if (path === '/') {
    headerLink.color = primaryColorDarkBG
    headerMenu.backgroundColor = primaryColorDarkBG
    headerLogo.value = whiteLogo
    headerBar.position = 'absolute'
  } else {
    headerLink.color = primaryColor
    headerMenu.backgroundColor = primaryColor
    headerLogo.value = blackLogo
    headerBar.position = 'static'
  }
}

UpdateHeaderStyle(currentRoutePath.value)

// Change the style according to the current page
watch(
  () => currentRoute.path,
  (newPath) => {
    currentRoutePath.value = newPath
    UpdateHeaderStyle(newPath)
  }
)

const headerMobileMenuContent = ref(null)

const headerMobileHamburgerMenu = ref()
const headerMobileHamburgerMenuBar1 = ref()
const headerMobileHamburgerMenuBar2 = ref()
const headerMobileHamburgerMenuBar3 = ref()

// Open and close mobile hamburger menu
const switchMobileMenu = () => {
  headerMobileMenuContent.value.classList.toggle('header__mobile__hamburger__menu--open')
  headerMobileHamburgerMenu.value.classList.toggle('header__mobile__hamburger__menu--close')
  headerMobileHamburgerMenuBar1.value.classList.toggle('header__mobile__hamburger__menu__bar1--close')
  headerMobileHamburgerMenuBar2.value.classList.toggle('header__mobile__hamburger__menu__bar2--close')
  headerMobileHamburgerMenuBar3.value.classList.toggle('header__mobile__hamburger__menu__bar3--close')
  document.body.classList.toggle('body--fixed--position')
}

// Give back to the original hamburger menu color on the home page
const closeMenu = () => {
  headerMobileMenuContent.value.classList.remove('header__mobile__hamburger__menu--open')
  headerMobileHamburgerMenu.value.classList.remove('header__mobile__hamburger__menu--close')
  headerMobileHamburgerMenuBar1.value.classList.remove('header__mobile__hamburger__menu__bar1--close')
  headerMobileHamburgerMenuBar2.value.classList.remove('header__mobile__hamburger__menu__bar2--close')
  headerMobileHamburgerMenuBar3.value.classList.remove('header__mobile__hamburger__menu__bar3--close')
  document.body.classList.remove('body--fixed--position')
}
</script>

<template>
  <header class="header">
    <div class="header__mobile__hamburger__menu__content" ref="headerMobileMenuContent">
      <nav class="header__mobile__link" aria-hidden="true">
        <router-link to="/" @click="closeMenu" class="header__mobile__link__content">Accueil</router-link>
        <router-link to="/enjeux" @click="closeMenu" class="header__mobile__link__content">Enjeux</router-link>
        <router-link to="/service" @click="closeMenu" class="header__mobile__link__content">Service</router-link>
        <router-link to="/contact" @click="closeMenu" class="header__mobile__link__content">Contact</router-link>
      </nav>
    </div>
    <nav class="header__global__container" :style="headerBar">
      <div class="header__logo">
        <router-link to="/">
          <img rel="preload" :src="headerLogo" alt="logo Howeco" width="272" height="72.69" />
        </router-link>
      </div>
      <div class="header__link__container">
        <router-link to="/enjeux" :style="headerLink">Enjeux</router-link>
        <router-link to="/service" :style="headerLink">Service</router-link>
        <router-link to="/contact" :style="headerLink">Contact</router-link>
      </div>
      <div class="header__mobile__hamburger__menu" @click="switchMobileMenu" ref="headerMobileHamburgerMenu">
        <div class="header__mobile__hamburger__menu__bar" :style="headerMenu" ref="headerMobileHamburgerMenuBar1"></div>
        <div class="header__mobile__hamburger__menu__bar" :style="headerMenu" ref="headerMobileHamburgerMenuBar2"></div>
        <div class="header__mobile__hamburger__menu__bar" :style="headerMenu" ref="headerMobileHamburgerMenuBar3"></div>
      </div>
    </nav>
  </header>
</template>

<style scope lang="scss">
header nav {
  width: calc(100% - 9.375rem);
  max-width: calc(120rem - 9.375rem);
  left: 0;
  right: 0;
  margin: auto;
  padding: 1.875rem 4.688rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-family: DM sans, sans-serif;
    font-size: 1.125rem;
    font-weight: bold;

    &.router-link-exact-active {
      color: #cde3a4 !important;
    }
  }

  .header {
    &__logo {
      z-index: 1;

      img {
        width: 17rem;
      }
    }

    &__link__container {
      display: flex;
      gap: 1.875rem;
      z-index: 1;
    }
  }
}

.header {

  &__mobile__hamburger__menu,
  &__mobile__hamburger__menu__content {
    display: none;
  }
}

.body--fixed--position {
  overflow: hidden;
}

@media screen and (max-width: 48rem) {

  header nav {
    width: calc(100% - 3.75rem);
    padding: 1.875rem;

    .header {
      &__logo img {
        width: 6.563rem;
      }

      &__link__container {
        display: none;
      }
    }
  }

  .header {
    &__mobile__hamburger__menu {
      display: block;
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 3;

      &__bar {
        display: block;
        cursor: pointer;
        width: 100%;
        height: 0.25rem;
      }

      &__bar3--close {
        display: none;
      }

      &__bar1--close {
        background-color: var(--primary-color) !important;
        transform: rotate(-45deg) translate(0, 0.15rem);
      }

      &__bar2--close {
        background-color: var(--primary-color) !important;
        transform: rotate(45deg) translate(0, -0.15rem);
      }

      &--open {
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 2;
        background-color: var(--clear-background-color);
      }

      &--close {
        justify-content: center;
      }
    }

    &__mobile__link {
      margin: 6.563rem 1.875rem 0 1.875rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.563rem;

      &__content {
        font-size: 2rem;
        color: var(--primary-color);
      }

      &:visited {
        color: var(--primary-color);
      }
    }
  }
}
</style>
