<script setup>
import HomeBackgroundPictureXL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-2048x1365.jpg'
import HomeBackgroundPictureL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1780×1187.jpg'
import HomeBackgroundPictureM from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1460×973.jpg'
import HomeBackgroundPictureS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1060×707.jpg'
import HomeBackgroundPictureXS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-300x200.jpg'

const HomeBackgroundPictureSrcset = `${HomeBackgroundPictureXS} 300w, ${HomeBackgroundPictureS} 1060w, ${HomeBackgroundPictureM} 1460w, ${HomeBackgroundPictureL} 1780w, ${HomeBackgroundPictureXL} 2048w`
const HomeBackgroundPictureSizes = '100vw'
</script>

<template>
  <div class="service__cover">
    <h1 class="service__cover__title">Notre offre</h1>
    <img class="service__cover__image" :srcset="HomeBackgroundPictureSrcset" :sizes="HomeBackgroundPictureSizes"
      alt="escalier en béton avec un arbre au centre" :src="HomeBackgroundPictureXL" loading="lazy" />
  </div>
  <div class="service__content">
    <p class="service__content__paragraph">Howeco accompagne vos entreprises, projets et collaborateurs en leur
      permettant d’intégrer des nouveaux éléments dans vos offres. Bilan Carbone, partenaires locaux, matériaux
      bio-sourcés, recommandations sociétales et écologiques… Retrouvez sur Howeco le moyen de mettre en pratique des
      notions qui peuvent encore sembler vagues pour vous ou vos collaborateurs.
      Nous ne sommes ni Assistants Maitre d’Oeuvre ni écologistes… Notre mission est de créer pour vous un puissant outil
      d’intelligence artificielle qui vous assiste et vous permet de prendre les décisions en accord avec votre stratégie
      et votre positionnement.</p>
  </div>
</template>

<style scope lang="scss">
.service__cover {
  position: relative;
  display: flex;
  align-items: center;
  margin: 9.375rem auto;
  width: calc(100% - 18.75rem);
  max-width: calc(120rem - 55.625rem);

  &__title {
    font-family: DM sans, sans-serif;
    font-weight: bold;
    font-size: 4rem;
    position: absolute;
    margin: 0;
    left: -4.688rem;
    z-index: 1;
  }

  &__image {
    width: 100%;
    max-width: 73.125rem;
    height: 31.875rem;
    object-fit: cover;
    opacity: 0.2;
    position: relative;
    left: 4.688rem;
    border-radius: 0.375rem;
  }
}

.service__content__paragraph {
  font-family: roboto, sans-serif;
  font-size: 1rem;
  margin: auto;
  margin-bottom: 0.938rem;
  text-align: left;
  width: calc(100% - 9.375rem);
  max-width: calc(120rem - 65rem);
}

@media screen and (max-width: 48rem) {
  .service__cover {
    width: calc(100% - 6.563rem);
    margin: 4.688rem auto 2.813rem auto;

    &__title {
      font-size: 2.25rem;
      top: 1.25rem;
      left: -1.406rem;
    }

    &__image {
      height: 14.688rem;
      left: 1.406rem;
    }
  }

  .service__content__paragraph {
    width: calc(100% - 3.75rem);
    font-size: 1rem;
  }
}
</style>
