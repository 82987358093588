<script setup>
import HomeBackgroundPictureXL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-2048x1365.jpg'
import HomeBackgroundPictureL from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1780×1187.jpg'
import HomeBackgroundPictureM from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1460×973.jpg'
import HomeBackgroundPictureS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-1060×707.jpg'
import HomeBackgroundPictureXS from '../../assets/images/alexander-abero-OypnYfdiQgg-unsplash-300x200.jpg'

const HomeBackgroundPictureSrcset = `${HomeBackgroundPictureXS} 300w, ${HomeBackgroundPictureS} 1060w, ${HomeBackgroundPictureM} 1460w, ${HomeBackgroundPictureL} 1780w, ${HomeBackgroundPictureXL} 2048w`
const HomeBackgroundPictureSizes = '100vw'
</script>

<template>
  <div class="issue__cover">
    <h1 class="issue__cover__title">Les enjeux</h1>
    <img class="issue__cover__image" :srcset="HomeBackgroundPictureSrcset" :sizes="HomeBackgroundPictureSizes"
      alt="escalier en béton avec un arbre au centre" :src="HomeBackgroundPictureXL" loading="lazy" />
  </div>
  <div class="issue__content">
    <p class="issue__content__paragraph">L’acte de construire se réinvente. Autrefois en marge du secteur de
      l’innovation ou de la numérisation, le secteur
      du BTP a souvent été perçu comme mature et stable. La stabilité du secteur en France à toujours été signe de bonne
      santé du marché et n’a pas suscité davantage de motivation au changement.
      Aujourd’hui, le secteur de la construction et promotion immobilière fait face à des défis inédits qui vont avoir des
      effets restructurants très importants. La mise en place d’une reflexion autour d’une nouvelle façon de construire,
      et de concevoir les habitats de lieux de vie, est importante plus que jamais.
      Les problématiques environnementales, sociales et économiques sont au coeur de notre démarche et reflètent la nature
      du marché de l’immobilier en France et plus globalement en Europe.</p>

    <p class="issue__content__paragraph">Les changements ne sont pas seulement d’ordre écologiques ou
      environnementaux. On estime que 75% des décisions
      d’achats de logements neufs sont motivés par l’opportunité de niche fiscale offerte par le dispositif Pinel. La fin
      programmée de la loi Pinel représente un risque économique pour l’ensemble de la filière.
      A cela, s’ajoute aussi les taux d’emprunts records et le durcissement des conditions d’accès à l’emprunt qui ont
      divisé en 2 le nombre de crédits immobiliers accordés.</p>

    <p class="issue__content__paragraph">Les mécanismes d’achats vont donc évoluer dans les années à venir. Les
      acheteurs pourront se retrouver en position
      de force, et avoir de nouveaux leviers de négociations. Différencier ses constructions sera donc un facteur clé
      auprès des acheteurs.</p>
  </div>
</template>

<style scope lang="scss">
.issue__cover {
  position: relative;
  display: flex;
  align-items: center;
  margin: 9.375rem auto;
  width: calc(100% - 18.75rem);
  max-width: calc(120rem - 55.625rem);

  &__title {
    font-family: DM sans, sans-serif;
    font-weight: bold;
    font-size: 4rem;
    position: absolute;
    margin: 0;
    left: -4.688rem;
    z-index: 1;
  }

  &__image {
    width: 100%;
    max-width: 73.125rem;
    height: 31.875rem;
    object-fit: cover;
    opacity: 0.2;
    position: relative;
    left: 4.688rem;
    border-radius: 0.375rem;
  }
}

.issue__content__paragraph {
  font-family: roboto, sans-serif;
  font-size: 1rem;
  margin: auto;
  margin-bottom: 0.938rem;
  text-align: left;
  width: calc(100% - 9.375rem);
  max-width: calc(120rem - 65rem);
}

@media screen and (max-width: 48rem) {
  .issue__cover {
    width: calc(100% - 6.563rem);
    margin: 4.688rem auto 2.813rem auto;

    &__title {
      font-size: 2.25rem;
      top: 1.25rem;
      left: -1.406rem;
    }

    &__image {
      height: 14.688rem;
      left: 1.406rem;
    }
  }

  .issue__content__paragraph {
    width: calc(100% - 3.75rem);
    font-size: 1rem;
  }
}
</style>
