<script setup defer>
import { ref, onMounted, watch } from 'vue'

import SecondaryButtonComponent from '../SecondaryButtonComponent.vue'
import visual1CityStep1 from '../../assets/images/visual_1/visual-1-city-step-1.svg'
import visual1CityStep2 from '../../assets/images/visual_1/visual-1-city-step-2.svg'
import visual1WheelStep1 from '../../assets/images/visual_1/visual-1-wheel-step-1.svg'
import visual1WheelStep2 from '../../assets/images/visual_1/visual-1-wheel-step-2.svg'

// ref linked to the visual elements of our illustration
const refVisual1CityStep1 = ref()
const refVisual1CityStep2 = ref()
const refVisual1WheelStep1 = ref()
const refVisual1WheelStep2 = ref()

// Indicate if need to activate the animation
const isWheelActive = ref(false)

const activateWheel = () => {
  isWheelActive.value = true
}

onMounted(() => {
  // Calculate the position of our issue part div
  const divToTrackForWheel = document.getElementById('scroll-position')
  const distanceToTopIssuePart = divToTrackForWheel.getBoundingClientRect().top
  const marginTopDivToTrackForWheel = parseFloat(getComputedStyle(divToTrackForWheel).marginTop)
  const scrollPosition = window.scrollY || window.pageYOffset
  const scrollToReachIssuePart = scrollPosition + distanceToTopIssuePart - marginTopDivToTrackForWheel

  // If animate is true, launch the animation
  watch(isWheelActive, (newValue) => {
    if (newValue) {
      refVisual1CityStep1.value.classList.add('home__issue__images__content__city__1--masked')
      refVisual1CityStep2.value.classList.add('home__issue__images__content__city__2--visible')
      refVisual1WheelStep1.value.classList.add('home__issue__images__content__wheel__1--masked')
      refVisual1WheelStep2.value.classList.add('home__issue__images__content__wheel__2--visible')
    }
  })

  // Check if the page is scroll enough to launch the animation, if yes remove the event listener
  const handleScrollForWheel = () => {
    const scrollPosition2 = window.scrollY || window.pageYOffset

    if (scrollPosition2 >= scrollToReachIssuePart) {
      activateWheel()
      window.removeEventListener('scroll', handleScrollForWheel)
    }
  }
  window.addEventListener('scroll', handleScrollForWheel)
})
</script>

<template>
  <div class="home__issue" id="scroll-position">
    <div class="home__issue__text">
      <h2 class="home__issue__text__title">Les enjeux</h2>
      <h3 class="home__issue__text__sub__title">43%</h3>
      <p class="home__issue__text__paragraph">C’est la proportion alarmante de la part du BTP en France du bilan
        carbone national, et qui a poussé les pouvoirs publics à agir et réglementer davantage le secteur. Des
        reglementations successives se sont mises en place, comme la RT2012, puis RE2020 afin de conduire au
        changement.</p>
      <p class="home__issue__text__paragraph">De nouvelles solutions existent et se développent, parmi lesquelles
        l’innovation des matériaux de constructions, la mise en place du réemploi, et le recyclage. Parfois
        difficile, la veille sur ces solutions restent peu solicitées par les petites et moyennes entreprises du
        secteur qui représentent pourtant une partie non négligeable du secteur. Howeco facilite la mise en relation
        des ces solutions et leur implémentation dans les process décisionnels.</p>
      <router-link to="/enjeux">
        <SecondaryButtonComponent class="home__issue__text__button" buttonContent="En savoir plus" />
      </router-link>
    </div>
    <div class="home__issue__images" aria-hidden="true">
      <div class="home__issue__images__content">
        <div class="home__issue__images__content__city">
          <img :src="visual1CityStep1" class="home__issue__images__content__city__1" alt="illustration ville grise"
            ref="refVisual1CityStep1" width="420" height="420" />
          <img :src="visual1CityStep2" class="home__issue__images__content__city__2" alt="illustration ville verte"
            ref="refVisual1CityStep2" width="420" height="420" />
        </div>
        <div class="home__issue__images__content__wheel">
          <img :src="visual1WheelStep1" class="home__issue__images__content__wheel__1"
            alt="illustration roue rouge point exclamation" ref="refVisual1WheelStep1" width="420" height="420" />
          <img :src="visual1WheelStep2" class="home__issue__images__content__wheel__2"
            alt="illustration roue verte décorative" ref="refVisual1WheelStep2" width="420" height="420" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scope lang="scss" onload="this.media='all'">
.home__issue {
  position: relative;
  width: calc(100% - 9.375rem);
  max-width: calc(120rem - 46.875rem);
  margin: 25vh auto 12.5vh auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    text-align: left;

    &__title,
    &__sub__title,
    &__paragraph {
      width: 26.25rem;
    }

    &__title {
      font-size: 2.5rem;
      margin-bottom: 1.313rem;
      font-family: DM sans, sans-serif;
    }

    &__sub__title {
      font-size: 1.5rem;
      margin-bottom: 0.875rem;
      font-family: DM sans, sans-serif;
    }

    &__paragraph {
      font-size: 1rem;
      font-family: roboto, sans-serif;
    }

    &__button {
      margin-top: 3.25rem;
    }
  }

  &__images {
    width: 26.25rem;

    &__content {
      position: relative;

      & img {
        width: 100%;
      }

      &__city,
      &__wheel {
        position: relative;
      }

      &__city {
        position: absolute;
        width: 100%;
      }

      &__city__2,
      &__wheel__2 {
        opacity: 0;
        transition: opacity 0.6s, rotate 0.6s ease-out;
      }

      &__wheel__2 {
        z-index: 1;
      }

      &__city__2 {
        position: relative;
        z-index: 2;
      }

      &__city__1,
      &__wheel__1 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        transition: opacity 0.6s, rotate 0.6s ease-out;
      }

      &__wheel__1--masked,
      &__city__1--masked {
        opacity: 0;
      }

      &__city__2--visible,
      &__wheel__2--visible {
        opacity: 1;
      }

      &__wheel__1--masked,
      &__wheel__2--visible {
        rotate: 90deg;
      }
    }
  }
}

@media screen and (max-width: 72rem) {
  .home__issue {
    flex-direction: column-reverse;

    &__images {
      width: 22.5rem;
    }
  }
}

@media screen and (max-width: 48rem) {
  .home__issue {
    width: calc(100% - 3.75rem);

    &__text__title,
    &__text__sub__title,
    &__text__paragraph {
      width: 100%;
    }

    &__images {
      width: calc(100% - 3.75rem);
      max-width: 22.5rem;
    }
  }
}
</style>
